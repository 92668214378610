import React, { useState, useEffect } from 'react';
import { ReactMic } from 'react-mic';
import axios from 'axios';

const SpeechToText = () => {

  const [errors, setErrors] = useState([]);
  const [success, setSuccess] = useState(false);
  const [message, setMessage] = useState('');
  const [submitBtnLoading, setSubmitBtnLoading] = useState(false);

  const [text, setText] = useState('');

  const [isRecording, setIsRecording] = useState(false);
  const [audioBlob, setAudioBlob] = useState(null);

  const startRecording = () => {
    setIsRecording(true);
  };

  const stopRecording = () => {
    setIsRecording(false);
  };

  const onData = (recordedData) => {
    // Do something with the recorded audio data if needed
    console.log('Recording...');
  };

  const onStop = (recordedBlob) => {
    setAudioBlob(recordedBlob.blob);
    console.log('Recording stopped:', recordedBlob);
  };

  const sendAudioToAPI = async () => {
    setSubmitBtnLoading(true);
    
    if (audioBlob) {
      const formData = new FormData();
      formData.append('file', audioBlob, 'recordedAudio.wav');
      formData.append('language', 'EN');

      const response = await axios.post('https://backendai1.klee.com/api/v1/speechToText/upload-and-transcribe', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(response => {
        if (response.data.success === true) {
          const emptyArray = [];
          setErrors(emptyArray);
          setSuccess(true);
          if (response.data.message) {
            setMessage(response.data.message);
          } else {
            setMessage("Transcript fetched!!");
          }

          setText(response.data.transcription);
        } else {
          setSuccess(false);
          setErrors([response.data.errors.message]);
        }

        setSubmitBtnLoading(false);
      })
      .catch(error => {
        setSuccess(false);
        setErrors([error.response.data.errors.message]);
        setSubmitBtnLoading(false);
      });
        
      console.log('API Response:', response.data);
    } else {
      console.warn('No audio to send.');
      setSubmitBtnLoading(false);
    }
  };

  
  useEffect(() => {
    
  }, []);

  return (
    <div className='pageSection w-100'>

      <div className={`alert alert-success formValidationWrapper ${success ? 'd-block' : 'd-none'}`}>
        <p className='mb-0'>{message}</p>
      </div>
      <div className={`alert alert-danger text-left formValidationWrapper ${errors.length > 0 ? 'd-block' : 'd-none'}`}>
        {
          errors.map((error, index) => {
            return (<p key={index} className='mb-0'> {error} </p>);
          })
        }
      </div>

      <div className='container-fluid'>
        <div className='row'>
          <div className="col-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 mx-auto">
            <div className='formSectionWrapper'>
              <div className="formSection">
                <div className="form-group">
                  <h3 className='mb-0 text-center'>Speech To Text</h3>
                  <ReactMic
                    record={isRecording}
                    className="sound-wave"
                    onStop={onStop}
                    onData={onData}
                    strokeColor="#000000"
                    backgroundColor="#FFF"
                  />
                  <div>
                    {
                    isRecording ? 
                    <button className='btn btn-sm btn-danger mt-2' onClick={stopRecording} disabled={!isRecording}><i className="bi-mic-mute-fill"></i></button>
                    :
                    <button className='btn btn-sm btn-info mt-2' onClick={startRecording} disabled={isRecording}><i className="bi bi-mic"></i></button>
                    }
                  </div>
                  <div className="w-100 d-flex justify-content-center mb-5">
                    {
                      submitBtnLoading ?
                        <button id="formbuttontranscriptionfetching" className="btn btn-light" type="submit">
                          <img src='../img/loader.gif' style={{height: '18px', marginRight: '10px'}}></img>
                          <span>Transcription fetching...</span>
                        </button>
                        :
                        <button id="formbuttonsubmit" className="btn btn-info" type="submit" onClick={sendAudioToAPI} disabled={!audioBlob}>
                          <span>Submit</span>
                        </button>
                    }
                  </div>
                  

                  <textarea className="form-control" id="exampleFormControlTextarea1" rows="6" value={text} onChange={(e) => { setText(e.target.value) }}></textarea>
                </div>
                
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  );
};

export default SpeechToText;
import React, { useState, useEffect, useRef } from 'react';
import { ReactMic } from 'react-mic';
import axios from 'axios';
import { useParams } from 'react-router-dom';

const moment = require('moment');

const Conversation = () => {
  const { id } = useParams();

  let defaultConversation = [
    {
      from: 'ai', //0 for ai
      message: "Hey"
    }
  ];

  const [errors, setErrors] = useState([]);
  const [success, setSuccess] = useState(false);
  const [message, setMessage] = useState('');
  const [submitBtnLoading, setSubmitBtnLoading] = useState(false);
  const [conversationStarted, setConversationStarted] = useState(false);
  
  const [text, setText] = useState('');
  const [uploadedFile, setUploadedFile] = useState({});

  const [isRecording, setIsRecording] = useState(false);
  const [audioBlob, setAudioBlob] = useState(null);
  const [conversations, setConversations] = useState([]);
  const [memory, setMemory] = useState(null);

  const sendVoiceBtn = useRef(null);
  const textareaRef = useRef(null);

  const [textToSpeechUrl, setTextToSpeechUrl] = useState('');

  const clickStartBtn = async (e) => {
    e.preventDefault();
    setConversationStarted(!conversationStarted);
    getFirstConversation(e);

    // defaultConversation = [
    //   {
    //     from: 0, //0 for ai
    //     message: firstConversation
    //   }
    // ];

    // setConversations(defaultConversation);
  };
  const clickStopBtn = () => {
    setConversationStarted(!conversationStarted);
  };


  const getFirstConversation = async (e) => {
    e.preventDefault();

    let history = JSON.stringify(conversations);

    setSubmitBtnLoading(true);

    const emptyArray = [];
    setErrors(emptyArray);

    const url = `https://backendai1.klee.com/api/v1/speechToText/conversation`;

    const headers = {
      'Accept': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Credentials': 'true',
      'Access-Control-Allow-Headers': 'accept, Content-type'
    }

    const data = new FormData();
    data.append('id', id);
    data.append('memory', memory);
    data.append('prompt', text);
    data.append('history', history);
    data.append('starter', true);

    const response = await axios.post(url, data, { headers: headers })
      .then(response => {
        if (response.data.success === true) {
          const emptyArray = [];
          setErrors(emptyArray);
          setSuccess(true);
          if (response.data.message) {
            setMessage(response.data.message);
          } else {
            setMessage("Conversation fetched!!");
          }

          setMemory(response.data.bufferMemory);
          let aiAnswer = {
            from: 'ai', 
            message: response.data.data
          };
          setConversations(prevState => [ ...prevState, aiAnswer ]);
          setSubmitBtnLoading(false);
          setTextToSpeechUrl(response.data.fileUrl);
          handlePlayAudio(response.data.fileUrl);

        } else {
          setSuccess(false);
          setErrors([(response.data.errors.message ? response.data.errors.message : response.data.message)]);
          setSubmitBtnLoading(false);
        }

      })
      .catch(error => {
        setSuccess(false);
        console.log(error);
        setErrors([error.response.data.message]);
        setSubmitBtnLoading(false);
      });
  }
  
  const startRecording = () => {
    setIsRecording(true);
  };

  const stopRecording = () => {
    setIsRecording(false);

    setTimeout(() => {
      if (sendVoiceBtn.current) {
        // Programmatically click the button
        sendVoiceBtn.current.click();
      } else {
        console.error('Button ref not assigned to a DOM element');
      }
    }, 1000);
    
  };

  const onData = (recordedData) => {
    // Do something with the recorded audio data if needed
    console.log('Recording...');
  };

  const onStop = (recordedBlob) => {
    setAudioBlob(recordedBlob.blob);
    // console.log('Recording stopped:', recordedBlob.blob);
  };

  const clickSendVoiceBtn = () => {
    sendAudioToAPI();
  };

  const sendAudioToAPI = async () => {
    setSubmitBtnLoading(true);
    
    if (audioBlob) {
      const formData = new FormData();
      formData.append('file', audioBlob, 'recordedAudio.wav');
      formData.append('language', uploadedFile.lang);

      const response = await axios.post('https://backendai1.klee.com/api/v1/speechToText/upload-and-transcribe', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(response => {
        if (response.data.success === true) {
          const emptyArray = [];
          setErrors(emptyArray);
          setSuccess(true);
          if (response.data.message) {
            setMessage(response.data.message);
          } else {
            setMessage("Transcript fetched!!");
          }

          setText((text) => text + ' ' + response.data.transcription);
        } else {
          setSuccess(false);
          setErrors([response.data.errors.message]);
        }

        setSubmitBtnLoading(false);
      })
      .catch(error => {
        setSuccess(false);
        setErrors([error.response.data.errors.message]);
        setSubmitBtnLoading(false);
      });
        
      // console.log('API Response:', response);
    } else {
      console.warn('No audio to send.');
      setSubmitBtnLoading(false);
    }
  };

  const submitForm = async (e) => {
    // e.preventDefault();

    let newQueryObj = {
      from: 'user', 
      message: text
    };
    setConversations(prevState => [ ...prevState, newQueryObj ]);
    setText('');

    let history = JSON.stringify(conversations);

    setSubmitBtnLoading(true);

    const emptyArray = [];
    setErrors(emptyArray);

    const url = `https://backendai1.klee.com/api/v1/speechToText/conversation`;

    const headers = {
      'Accept': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Credentials': 'true',
      'Access-Control-Allow-Headers': 'accept, Content-type'
    }

    const data = new FormData();
    data.append('id', id);
    data.append('memory', memory);
    data.append('prompt', text);
    data.append('history', history);
    data.append('starter', false);

    const response = await axios.post(url, data, { headers: headers })
      .then(response => {
        if (response.data.success === true) {
          const emptyArray = [];
          setErrors(emptyArray);
          setSuccess(true);
          if (response.data.message) {
            setMessage(response.data.message);
          } else {
            setMessage("Conversation fetched!!");
          }

          setMemory(response.data.bufferMemory);
          let aiAnswer = {
            from: 'ai', 
            message: response.data.data
          };
          setConversations(prevState => [ ...prevState, aiAnswer ]);
          setSubmitBtnLoading(false);
          setTextToSpeechUrl(response.data.fileUrl);
          handlePlayAudio(response.data.fileUrl);

        } else {
          setSuccess(false);
          setErrors([(response.data.errors.message ? response.data.errors.message : response.data.message)]);
          setSubmitBtnLoading(false);
        }

      })
      .catch(error => {
        setSuccess(false);
        console.log(error);
        setErrors([error.response.data.message]);
        setSubmitBtnLoading(false);
      });
  }


  const clickNextButton = () => {
    
  };

  const clickEditButton = () => {
    if (textareaRef.current) {
      // Focus on the textarea
      textareaRef.current.focus();
    } else {
      console.error('Textarea ref not assigned to a DOM element');
    }
  };


  const getUploadedFileById = async (id) => {
    
      const formData = new FormData();
      formData.append('id', id);

      const response = await axios.post('https://backendai1.klee.com/api/v1/speechToText/get-uploaded-file-by-id', formData, {
        headers: {
          'Accept': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Credentials': 'true',
          'Access-Control-Allow-Headers': 'accept, Content-type'
        },
      })
      .then(response => {
        if (response.data.success === true) {
          const emptyArray = [];
          setErrors(emptyArray);
          setSuccess(true);
          if (response.data.message) {
            setMessage(response.data.message);
          } else {
            setMessage("File fetched!!");
          }

          setUploadedFile(response.data.data);
          console.log(response);
        } else {
          setSuccess(false);
          setErrors([response.data.errors.message]);
        }

        setSubmitBtnLoading(false);
      })
      .catch(error => {
        setSuccess(false);
        setErrors([error.response.data.errors.message]);
        setSubmitBtnLoading(false);
      });
        
  };


  const clickTextToSpeech = async (e) => {
    // console.log(e.target.innerText);
    setSubmitBtnLoading(true);

    const emptyArray = [];
    setErrors(emptyArray);

    const url = `https://backendai1.klee.com/api/v1/speechToText/get-text-to-speech`;

    const headers = {
      'Accept': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Credentials': 'true',
      'Access-Control-Allow-Headers': 'accept, Content-type'
    }

    const data = new FormData();
    data.append('input', e.target.innerText);

    const response = await axios.post(url, data, { headers: headers })
      .then(response => {
        if (response.data.success === true) {
          const emptyArray = [];
          setErrors(emptyArray);
          setSuccess(true);
          if (response.data.message) {
            setMessage(response.data.message);
          } else {
            setMessage("Text to speech fetched!!");
          }
          setSubmitBtnLoading(false);

          setTextToSpeechUrl(response.data.fileUrl);
          handlePlayAudio(response.data.fileUrl);

        } else {
          setSuccess(false);
          setErrors([(response.data.errors.message ? response.data.errors.message : response.data.message)]);
          setSubmitBtnLoading(false);
        }

      })
      .catch(error => {
        setSuccess(false);
        console.log(error);
        setErrors([error.response.data.message]);
        setSubmitBtnLoading(false);
      });
  };

  const handlePlayAudio = (textToSpeechUrl) => {
    const audioElement = new Audio(textToSpeechUrl);
    audioElement.preload = "auto";
    audioElement.play();
  };

  useEffect(() => {
    getUploadedFileById(id);
    console.log(conversations);

  }, []);

  return (
    <div className='pageSection w-100'>

      <div className={`alert alert-success formValidationWrapper ${success ? 'd-block' : 'd-none'}`}>
        <p className='mb-0'>{message}</p>
      </div>
      <div className={`alert alert-danger text-left formValidationWrapper ${errors.length > 0 ? 'd-block' : 'd-none'}`}>
        {
          errors.map((error, index) => {
            return (<p key={index} className='mb-0'> {error} </p>);
          })
        }
      </div>

      <div className='container'>
        <div className='row'>
          <div className="col-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <h2 className='text-start mb-5'>User Page ({uploadedFile.title})</h2>
          </div>
        </div>
        
        <div className='row'>
          <div className="col-0 col-lg-4 col-md-3 col-sm-0 col-xs-0">
            <img src="https://thumbs.dreamstime.com/z/positive-millennial-man-casual-outfit-street-confident-handsome-young-unshaven-guy-t-shirt-arms-crossed-leaning-pole-231496570.jpg" 
              alt="Description of the image" className="img-fluid" />
            
          </div>
          <div className="col-12 col-lg-6 col-md-7 col-sm-12 col-xs-12">
            <div className='chatBox border w-100 mb-4 p-3' style={{minHeight: '50vh'}}>

            {conversations.map((conversation, index) => (
              <div className='w-100 d-flex mb-1' key={index}>
                <div className='text-end me-3' style={{width: '10%'}}>
                  <span>{conversation.from == 'user' ? 'You:' : 'Trent:' }</span>
                </div>
                <div className='text-start' style={{width: '90%'}}>
                  <span style={{cursor: 'pointer'}} onClick={(e) => clickTextToSpeech(e)}>{conversation.message}</span>
                </div>
              </div>
            ))}

            </div>


            <div className='inputBox d-flex mb-3'>
              <span className='me-4 mt-1'>You:</span>
              <textarea className="form-control" id="inputField" rows="2" value={text} onChange={(e) => { setText(e.target.value) }} ref={textareaRef}></textarea>
            </div>


            <div className='buttonBox d-flex justify-content-between'>
              <div className='mx-4'>
                {
                  conversationStarted ?
                    <button id="conversationStartedStop" className="btn btn-info mx-3 px-4" type="submit" onClick={clickStopBtn}>
                      <span>Stop</span>
                    </button>
                    :
                    <button id="conversationStartedStart" className="btn btn-info mx-3 px-4" type="submit" onClick={clickStartBtn}>
                      <span>Start</span>
                    </button>
                }

                <ReactMic
                  record={isRecording}
                  className="sound-wave d-none"
                  onStop={onStop}
                  onData={onData}
                  strokeColor="#000000"
                  backgroundColor="#FFF"
                />
                {
                isRecording ? 
                <button className='btn btn-danger mx-3' onClick={stopRecording} disabled={!isRecording || !conversationStarted}><i className="bi-mic-mute-fill"></i></button>
                :
                <button className='btn btn-info mx-3' onClick={startRecording} disabled={isRecording || !conversationStarted}><i className="bi bi-mic"></i></button>
                }
                <button id="sendVoiceBtn" className="d-none" type="submit" onClick={clickSendVoiceBtn} ref={sendVoiceBtn}>
                  <span>Send Voice</span>
                </button>

                <button id="editButton" className="btn btn-info mx-3 px-4" type="submit" onClick={clickEditButton} disabled={!conversationStarted}>
                  <span>Edit</span>
                </button>

                {
                  submitBtnLoading ?
                    <button id="answerfetching" className="btn btn-light mx-3 px-3" type="submit" disabled={!conversationStarted}>
                      <img src='../img/loader.gif' style={{height: '18px', marginRight: '10px'}}></img>
                      <span>Please wait...</span>
                    </button>
                    :
                    <button id="formbuttonsubmit" className="btn btn-info mx-3 px-3" type="submit" onClick={submitForm} disabled={!conversationStarted}>
                      <span>Submit</span>
                    </button>
                }
              </div>

              <button id="nextButton" className="btn btn-info px-4" type="submit" onClick={clickNextButton}>
                <span>Next</span>
              </button>

            </div>
            
          </div>


        </div>
        
      </div>
    </div>
  );
};

export default Conversation;
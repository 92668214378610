import React, { useState, useEffect } from 'react';
import axios from 'axios';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';

const TrainModel = () => {

  const [errors, setErrors] = useState([]);
  const [success, setSuccess] = useState(false);
  const [message, setMessage] = useState('');
  const [submitBtnLoading, setSubmitBtnLoading] = useState(false);

  const [title, setTitle] = useState('');
  const [file, setFile] = useState('');

  const {
    transcript,
    listening,
    resetTranscript,
    browserSupportsSpeechRecognition
  } = useSpeechRecognition();

  const startRecording = (e) => {
    e.preventDefault();
    SpeechRecognition.startListening({
      continuous: true
    });

  }
  const stopRecording = (e) => {
    e.preventDefault();
    SpeechRecognition.stopListening();
    setTitle(title+` `+transcript);
    resetTranscript();

  }


  const submitForm = async (e) => {
    e.preventDefault();
    setSubmitBtnLoading(true);

    const emptyArray = [];
    setErrors(emptyArray);

    const url = `https://backendai1.klee.com/api/v1/gpt3/train-model`;
    // const url = `http://localhost:3000/api/v1/gpt3/train-model`;

    const headers = {
      'Accept': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Credentials': 'true',
      'Access-Control-Allow-Headers': 'accept, Content-type'
    }

    const fileInput = document.querySelector('#file-input');
    const file = fileInput.files[0];

    const data = new FormData();
    data.append('title', title);
    data.append('file', file);

    const response = await axios.post(url, data, { headers: headers })
      .then(response => {
        if (response.data.success === true) {
          const emptyArray = [];
          setErrors(emptyArray);
          setSuccess(true);
          if (response.data.message) {
            setMessage(response.data.message);
          } else {
            setMessage("Training file uploaded !!");
          }
        } else {
          setSuccess(false);
          setErrors([(response.data.errors.message ? response.data.errors.message : response.data.message)]);
          setSubmitBtnLoading(false);
        }

        setSubmitBtnLoading(false);
      })
      .catch(error => {
        setSuccess(false);
        setErrors([error.response.data.message]);
        setSubmitBtnLoading(false);
      });
  }

  useEffect(() => {
    
  }, []);

  return (
    <div className='pageSection w-100'>

      <div className={`alert alert-success formValidationWrapper ${success ? 'd-block' : 'd-none'}`}>
        <p className='mb-0'>{message}</p>
      </div>
      <div className={`alert alert-danger text-left formValidationWrapper ${errors.length > 0 ? 'd-block' : 'd-none'}`}>
        {
          errors.map((error, index) => {
            return (<p key={index} className='mb-0'> {error} </p>);
          })
        }
      </div>

      <div className='container-fluid'>
        <div className='row'>
          <div className="col-9 col-lg-9 col-md-9 col-sm-12 col-xs-12">

            <div className='formSectionWrapper'>
              <div className="formSection">
                <div className="form-group">
                  <p className='mb-0'>Enter Title</p>
                  {
                    !browserSupportsSpeechRecognition ?
                    <p>Browser doesn't support speech recognition.</p>
                    :
                    <div className='d-flex'>
                      <p className=''>
                        {
                        listening ? 
                        <button className='btn btn-sm btn-danger mt-2' onClick={(e) => stopRecording(e)}><i className="bi-mic-mute-fill"></i></button>
                        :
                        <button className='btn btn-sm btn-info mt-2' onClick={(e) => startRecording(e)}><i className="bi bi-mic"></i></button>
                        }
                      </p>
                      <p className='ms-2 mt-2'>{transcript}</p>
                    </div>
                  }

                  <textarea className="form-control" id="exampleFormControlTextarea1" rows="6" value={title} onChange={(e) => { setTitle(e.target.value) }}></textarea>
                </div>
                <br></br>
                <div className="form-group">
                  <p>File</p>
                  <input type="file" className="form-range" style={{height: 'unset'}} id="file-input" name="file" value={file} onChange={(e) => { setFile(e.target.value) }} />
                </div>

                <div className="w-100 d-flex justify-content-end">
                  {
                    submitBtnLoading ?
                      <button id="formbutton" className="btn btn-light" type="submit">
                        <img src='./img/loader.gif' style={{height: '18px', marginRight: '10px'}}></img>
                        <span>File uploading...</span>
                      </button>
                      :
                      <button id="formbutton" className="btn btn-info" type="submit" onClick={(e) => submitForm(e)}>
                        <span>Submit</span>
                      </button>
                  }
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  );
};

export default TrainModel;
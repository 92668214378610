import React, { useState, useEffect } from 'react';
import axios from 'axios';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';

const HomeV2 = () => {

  const [errors, setErrors] = useState([]);
  const [success, setSuccess] = useState(false);
  const [message, setMessage] = useState('');
  const [submitBtnLoading, setSubmitBtnLoading] = useState(false);
  const [submitBtnMsg, setSubmitBtnMsg] = useState('Correction fetching...');

  const [prePrompt, setPrePrompt] = useState('Correct this to standard English:');
  const [text, setText] = useState('');
  const [correction, setCorrection] = useState('');

  const [models, setModels] = useState([]);
  const [modelId, setModelId] = useState('');
  const [temperature, setTemperature] = useState(0);
  const [maxLength, setMaxLength] = useState(2000);
  const [topP, setTopP] = useState(1);
  const [frequencyPenalty, setFrequencyPenalty] = useState(0);
  const [presencePenalty, setPresencePenalty] = useState(0);
  const [bestOf, setBestOf] = useState(1);

  const {
    transcript,
    listening,
    resetTranscript,
    browserSupportsSpeechRecognition
  } = useSpeechRecognition();

  const fetchModels = async () => {
    const emptyArray = [];
    setErrors(emptyArray);

    const url = `https://backendai1.klee.com/api/v1/gpt3/get-all-models`;
    const headers = {
      'Accept': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Credentials': 'true',
      'Access-Control-Allow-Headers': 'accept, Content-type'
    }

    const response = await axios.get(url, { headers: headers })
      .then(response => {
        if (response.data.success === true) {
          const emptyArray = [];
          setErrors(emptyArray);
          setSuccess(true);
          if (response.data.message) {
            setMessage(response.data.message);
          } else {
            setMessage("Models fetched !!");
          }
          setModels(response.data.data.models);
          setModelId('text-davinci-003');
        } else {
          setSuccess(false);
          setErrors([response.data.errors.message]);
        }
      })
      .catch(error => {
        console.log(error);
        setSuccess(false);
        setErrors([error.response.data.errors.message]);
      });
  }

  const startRecording = (e) => {
    e.preventDefault();
    SpeechRecognition.startListening({
      continuous: true
    });

  }
  const stopRecording = (e) => {
    e.preventDefault();
    SpeechRecognition.stopListening();
    setText(text+` `+transcript);
    resetTranscript();

  }

  
  const sendCorrectionRequest = async (e) => {
    e.preventDefault();
    setSubmitBtnLoading(true);

    const emptyArray = [];
    setErrors(emptyArray);

    const url = `https://backendai1.klee.com/api/v1/gpt3/send-only-correction`;

    const headers = {
      'Accept': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Credentials': 'true',
      'Access-Control-Allow-Headers': 'accept, Content-type'
    }

    const data = {
      prePrompt: prePrompt,
      prompt: text,
      model: modelId,
      temperature: temperature,
      maxTokens: maxLength,
      topP: topP,
      frequencyPenalty: frequencyPenalty,
      presencePenalty: presencePenalty,
      bestOf: bestOf
    };

    const response = await axios.post(url, data, { headers: headers })
      .then(response => {
        if (response.data.success === true) {
          const emptyArray = [];
          setErrors(emptyArray);
          setSuccess(true);
          if (response.data.message) {
            setMessage(response.data.message);
          } else {
            setMessage("Correction fetched !!");
          }

          let completionReply = response.data.data.completionReply;
          if(completionReply[0] == '\n'){
            completionReply = completionReply.slice(1);
              if(completionReply[0] == '\n'){
                completionReply = completionReply.slice(1);
              }
          }

          setCorrection(completionReply);
        } else {
          setSuccess(false);
          setErrors([response.data.errors.message]);
        }

        setSubmitBtnLoading(false);
      })
      .catch(error => {
        setSuccess(false);
        setErrors([error.response.data.errors.message]);
        setSubmitBtnLoading(false);
      });
  }


  useEffect(() => {
    fetchModels();
  }, []);

  return (
    <div className='pageSection w-100'>

      <div className={`alert alert-success formValidationWrapper ${success ? 'd-block' : 'd-none'}`}>
        <p className='mb-0'>{message}</p>
      </div>
      <div className={`alert alert-danger text-left formValidationWrapper ${errors.length > 0 ? 'd-block' : 'd-none'}`}>
        {
          errors.map((error, index) => {
            return (<p key={index} className='mb-0'> {error} </p>);
          })
        }
      </div>

      <div className='container-fluid'>
        <div className='row'>
          <div className="col-9 col-lg-9 col-md-9 col-sm-12 col-xs-12">

            <div className='formSectionWrapper'>
              <div className="formSection">
                <div className="form-group">
                  <p className='mb-0'>Enter Text</p>
                  <textarea className="form-control" id="formControlTextareaPrePrompt" rows="3" value={prePrompt} onChange={(e) => { setPrePrompt(e.target.value) }}></textarea>

                  {
                    !browserSupportsSpeechRecognition ?
                    <p>Browser doesn't support speech recognition.</p>
                    :
                    <div className='d-flex'>
                      <p className=''>
                        {
                        listening ? 
                        <button className='btn btn-sm btn-danger mt-2' onClick={(e) => stopRecording(e)}><i className="bi-mic-mute-fill"></i></button>
                        :
                        <button className='btn btn-sm btn-info mt-2' onClick={(e) => startRecording(e)}><i className="bi bi-mic"></i></button>
                        }
                      </p>
                      <p className='ms-2 mt-2'>{transcript}</p>
                    </div>
                  }

                  <textarea className="form-control" id="formControlTextareaText" rows="6" value={text} onChange={(e) => { setText(e.target.value) }}></textarea>
                </div>
                <div className="w-100 d-flex justify-content-end">
                  {
                    submitBtnLoading ?
                      <button id="formbutton" className="btn btn-light" type="submit">
                        <img src='./img/loader.gif' style={{height: '18px', marginRight: '10px'}}></img>
                        <span>{submitBtnMsg}</span>
                      </button>
                      :
                      <button id="formbutton" className="btn btn-info" type="submit" onClick={(e) => sendCorrectionRequest(e)}>
                        <span>Submit</span>
                      </button>
                  }
                </div>
              </div>


              <div className="formSection">
                <div className="form-group">
                  <p>Corrected Text</p>
                  <textarea className="form-control" id="formControlTextareaCorrection" rows="6" value={correction} onChange={(e) => { setCorrection(e.target.value) }}></textarea>
                </div>
              </div>

            </div>

          </div>
          <div className="col-3 col-lg-3 col-md-3 col-sm-12 col-xs-12">

            <div className="controllerSectionWrapper">

              <div className="controllerSection">
                <div className="form-group">
                  <p className="controllerSectionHeading">GPT-3 parameter settings</p>
                </div>
              </div>

              <div className="controllerSection">
                <div className="form-group">
                  <p>Model</p>
                  <select className="form-control" id="exampleFormControlSelect" value={modelId} onChange={(e) => { setModelId(e.target.value) }}>
                    {
                      models.map((model, key) => {
                        return <option key={key} value={model.id}>{model.id}</option>
                      })
                    }
                  </select>
                </div>
              </div>

              <div className="controllerSection">
                <div className="form-group">
                  <div className='d-flex flex-row justify-content-between'>
                    <p>Temperature</p>
                    <p id='temperatureValue'>{temperature}</p>
                  </div>
                  <input type="range" className="form-range" min="0" max="1" step="0.01" value={temperature} onChange={(e) => { setTemperature(e.target.value) }} />
                </div>
              </div>

              <div className="controllerSection">
                <div className="form-group">
                  <div className='d-flex flex-row justify-content-between'>
                    <p>Maximum length</p>
                    <p id='maximumLengthValue'>{maxLength}</p>
                  </div>
                  <input type="range" className="form-range" min="1" max="4000" step="1" value={maxLength} onChange={(e) => { setMaxLength(e.target.value) }} />
                </div>
              </div>

              <div className="controllerSection">
                <div className="form-group">
                  <p style={{ marginBottom: '0px' }}>Stop sequences</p>
                  <p style={{ fontSize: '13px', color: 'grey', marginBottom: '8px' }}>Enter sequence and press tab</p>
                  <input type="text" className="form-control" />
                </div>
              </div>

              <div className="controllerSection">
                <div className="form-group">
                  <div className='d-flex flex-row justify-content-between'>
                    <p>Top P</p>
                    <p id='topPValue'>{topP}</p>
                  </div>
                  <input type="range" className="form-range" min="0" max="1" step="0.01" value={topP} onChange={(e) => { setTopP(e.target.value) }} />
                </div>
              </div>

              <div className="controllerSection">
                <div className="form-group">
                  <div className='d-flex flex-row justify-content-between'>
                    <p>Frequency penalty</p>
                    <p id='frequencyPenaltyValue'>{frequencyPenalty}</p>
                  </div>
                  <input type="range" className="form-range" min="0" max="2" step="0.01" value={frequencyPenalty} onChange={(e) => { setFrequencyPenalty(e.target.value) }} />
                </div>
              </div>

              <div className="controllerSection">
                <div className="form-group">
                  <div className='d-flex flex-row justify-content-between'>
                    <p>Presence penalty</p>
                    <p id='presencePenaltyValue'>{presencePenalty}</p>
                  </div>
                  <input type="range" className="form-range" min="0" max="2" step="0.01" value={presencePenalty} onChange={(e) => { setPresencePenalty(e.target.value) }} />
                </div>
              </div>

              <div className="controllerSection">
                <div className="form-group">
                  <div className='d-flex flex-row justify-content-between'>
                    <p>Best of</p>
                    <p id='bestOfValue'>{bestOf}</p>
                  </div>
                  <input type="range" className="form-range" min="1" max="20" step="1" value={bestOf} onChange={(e) => { setBestOf(e.target.value) }} />
                </div>
              </div>

              <div className="controllerSection">
                <div className="form-group">
                  <p>Inject start text</p>
                </div>
              </div>

              <div className="controllerSection">
                <div className="form-group">
                  <p>Inject restart text</p>
                </div>
              </div>

              <div className="controllerSection">
                <div className="form-group">
                  <p>Show possibilities</p>
                  <select className="form-control" id="exampleFormControlSelect1">
                    <option>Off</option>
                    <option>Most Likely</option>
                    <option>Least Likely</option>
                    <option>Full Spectrum</option>
                  </select>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeV2;
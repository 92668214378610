import React, { useEffect }  from 'react';

import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
// import logo from './logo.svg';
import './App.css';

import Home from '../src/components/Home';
import HomeV2 from '../src/components/HomeV2';
import Correction from '../src/components/Correction';
import Translation from '../src/components/Translation';
import FineTune from '../src/components/FineTune';
import TrainModel from '../src/components/TrainModel';
import TrainModelJsonl from '../src/components/TrainModelJsonl';
import Topic from '../src/components/Topic';
import UploadFile from './components/UploadFile';
import Query from '../src/components/Query';
import SupportUploadFile from './components/SupportUploadFile';
import Query4 from '../src/components/Query4';
import SupportUploadFile4 from './components/SupportUploadFile4';
import SpeechToText from './components/SpeechToText';
import ManagerPageCreate from './components/ManagerPageCreate';
import ManagerPageEdit from './components/ManagerPageEdit';
import ManagerPageList from './components/ManagerPageList';
import Conversation from './components/Conversation';

function App() {

  useEffect(() => {
    
    
  }, []);

  return (
    <div className="App">
        <Router>
          <Routes>
            <Route exact path="/v-1" element={<Home/>}/>
            <Route exact path="/v-2" element={<HomeV2/>}/>
            <Route exact path="/correction" element={<Correction/>}/>
            <Route exact path="/translation" element={<Translation/>}/>
            <Route exact path="/fine-tune" element={<FineTune/>}/>
            <Route exact path="/train-model" element={<TrainModel/>}/>
            <Route exact path="/train-model-jsonl" element={<TrainModelJsonl/>}/>
            <Route exact path="/topic" element={<Topic/>}/>
            <Route exact path="/upload-file" element={<UploadFile/>}/>

            <Route exact path="/ssupport_1/query" element={<Query/>}/>
            <Route exact path="/ssupport_1/upload-file" element={<SupportUploadFile/>}/>

            <Route exact path="/ssupport4_1/query" element={<Query4/>}/>
            <Route exact path="/ssupport4_1/upload-file" element={<SupportUploadFile4/>}/>

            <Route exact path="/speech-to-text" element={<SpeechToText/>}/>
            <Route exact path="/manager-page/create" element={<ManagerPageCreate/>}/>
            <Route exact path="/manager-page/list" element={<ManagerPageList/>}/>
            <Route exact path="/manager-page/edit/:id" element={<ManagerPageEdit/>}/>
            <Route exact path="/conversation/:id" element={<Conversation/>}/>
          </Routes>
        </Router>
      </div>
  );
}

export default App;

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';

const moment = require('moment');

const ManagerPageList = () => {

  const [errors, setErrors] = useState([]);
  const [success, setSuccess] = useState(false);
  const [message, setMessage] = useState('');
  const [submitBtnLoading, setSubmitBtnLoading] = useState(false);
  const [removeBtnLoading, setRemoveBtnLoading] = useState(false);

  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [removeFileId, setRemoveFileId] = useState(0);


  const getUploadedFiles = async () => {
    const emptyArray = [];
    setErrors(emptyArray);

    const url = `https://backendai1.klee.com/api/v1/speechToText/get-uploaded-files`;

    const headers = {
      'Accept': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Credentials': 'true',
      'Access-Control-Allow-Headers': 'accept, Content-type'
    }

    const response = await axios.get(url, { headers: headers })
      .then(response => {
        if (response.data.success === true) {
          const emptyArray = [];
          setErrors(emptyArray);
          setSuccess(true);
          if (response.data.message) {
            setMessage(response.data.message);
          } else {
            setMessage("Uploaded files fetched successfully for speech to text!!");
          }
          setUploadedFiles(response.data.data);

        } else {
          setSuccess(false);
          setErrors([response.data.errors.message]);
          setSubmitBtnLoading(false);
        }

        setSubmitBtnLoading(false);
      })
      .catch(error => {
        setSuccess(false);
        setErrors([error.response.data.errors.message]);
        setSubmitBtnLoading(false);
      });
  }

  const removeFile = async (e, id, filename, vectorStoreName) => {
    e.preventDefault();
    setRemoveFileId(id);
    setRemoveBtnLoading(true);

    const emptyArray = [];
    setErrors(emptyArray);

    const url = `https://backendai1.klee.com/api/v1/speechToText/delete-uploaded-file`;

    const headers = {
      'Accept': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Credentials': 'true',
      'Access-Control-Allow-Headers': 'accept, Content-type'
    }

    const data = {
      id: id,
      filename: filename,
      vectorStoreName: vectorStoreName
    };

    const response = await axios.post(url, data, { headers: headers })
      .then(response => {
        if (response.data.success === true) {
          const emptyArray = [];
          setErrors(emptyArray);
          setSuccess(true);
          if (response.data.message) {
            setMessage(response.data.message);
          } else {
            setMessage("File deleted!!");
          }
          setRemoveBtnLoading(false);
          setRemoveFileId(0);
          getUploadedFiles();
        } else {
          setSuccess(false);
          setErrors([(response.data.errors.message ? response.data.errors.message : response.data.message)]);
          setRemoveBtnLoading(false);
          setRemoveFileId(0);
        }

      })
      .catch(error => {
        setSuccess(false);
        setErrors([error.response.data.message]);
        setRemoveBtnLoading(false);
        setRemoveFileId(0);
      });
  }

  useEffect(() => {
    getUploadedFiles();

  }, []);

  return (
    <div className='pageSection w-100'>

      <div className={`alert alert-success formValidationWrapper ${success ? 'd-block' : 'd-none'}`}>
        <p className='mb-0'>{message}</p>
      </div>
      <div className={`alert alert-danger text-left formValidationWrapper ${errors.length > 0 ? 'd-block' : 'd-none'}`}>
        {
          errors.map((error, index) => {
            return (<p key={index} className='mb-0'> {error} </p>);
          })
        }
      </div>

      <div className='container-fluid'>

        <div className='row'>
          <div className="col-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">

            <div className='formSection'>
              <h5 className='text-start'>Manager Page > View/Edit/Delete Topic Uploads</h5>
              <div className="mt-4">
                <table className="table text-start">
                  <thead>
                    <tr>
                      <th style={{width: '15%'}}></th>
                      <th style={{width: '5%'}}>SN</th>
                      <th style={{width: '5%'}}>ID</th>
                      <th>Title:</th>
                      <th>Language:</th>
                    </tr>
                  </thead>
                  <tbody>
                    {uploadedFiles.map((file, index) => (
                      <tr key={index}>
                        <td>
                          {
                            removeBtnLoading && (removeFileId == file.id) ?
                              <button id="formbutton" className="btn mt-0 py-0" type="submit">
                                <img src='../img/loader.gif' style={{ height: '18px', marginRight: '10px' }}></img>
                                <span>[File removing...]</span>
                              </button>
                              :
                              <button id="formbutton" className="btn mt-0 py-0" type="submit" onClick={(e) => removeFile(e, file.id, file.file_name, file.vector_store_name)}>
                                <span>[Delete]</span>
                              </button>
                          }
                          <Link to={`/manager-page/edit/${file.id}`} style={{textDecoration: 'none'}}> {/* Add your desired path in the "to" attribute */}
                            <button id="formbutton" className="btn mt-0 py-0">
                              <span>[Edit]</span>
                            </button>
                          </Link>
                        </td>
                        <td>{index+1}</td>
                        <td>{file.id}</td>
                        <td>{file.title}</td>
                        <td>{file.lang}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManagerPageList;
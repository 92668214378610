import React, { useState, useEffect } from 'react';
import axios from 'axios';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';

const Query = () => {

  const [errors, setErrors] = useState([]);
  const [success, setSuccess] = useState(false);
  const [message, setMessage] = useState('');
  const [submitBtnLoading, setSubmitBtnLoading] = useState(false);
  const [editSubmitBtnLoading, setEditSubmitBtnLoading] = useState(false);
  const [downloadBtnLoading, setDownloadBtnLoading] = useState(false);

  const [text, setText] = useState('');
  const [answer, setAnswer] = useState('');
  const [editAnswer, setEditAnswer] = useState('');
  const [result, setResult] = useState('');
  const [displayEditForm, setDisplayEditForm] = useState(0);


  const {
    transcript,
    listening,
    resetTranscript,
    browserSupportsSpeechRecognition
  } = useSpeechRecognition();

  const startRecording = (e) => {
    e.preventDefault();
    SpeechRecognition.startListening({
      continuous: true
    });

  }
  const stopRecording = (e) => {
    e.preventDefault();
    SpeechRecognition.stopListening();
    setText(text+` `+transcript);
    resetTranscript();

  }


  const submitForm = async (e) => {
    e.preventDefault();
    setSubmitBtnLoading(true);

    const emptyArray = [];
    setErrors(emptyArray);

    const url = `https://backendai1.klee.com/api/v1/topic/support/ask-from-topic`;

    const headers = {
      'Accept': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Credentials': 'true',
      'Access-Control-Allow-Headers': 'accept, Content-type'
    }

    const data = {
      prompt: text
    };

    const response = await axios.post(url, data, { headers: headers })
      .then(response => {
        if (response.data.success === true) {
          const emptyArray = [];
          setErrors(emptyArray);
          setSuccess(true);
          if (response.data.message) {
            setMessage(response.data.message);
          } else {
            setMessage("Answer fetched for support!!");
          }

          setAnswer(response.data.data.completionReply);
        } else {
          setSuccess(false);
          setErrors([response.data.errors.message]);
        }

        setSubmitBtnLoading(false);
      })
      .catch(error => {
        setSuccess(false);
        setErrors([error.response.data.errors.message]);
        setSubmitBtnLoading(false);
      });
  }

  const createVectorStore = async () => {
    const emptyArray = [];
    setErrors(emptyArray);

    const url = `https://backendai1.klee.com/api/v1/topic/support/create-vector-store`;

    const headers = {
      'Accept': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Credentials': 'true',
      'Access-Control-Allow-Headers': 'accept, Content-type'
    }

    const data = {

    };

    const response = await axios.post(url, data, { headers: headers })
      .then(response => {
        if (response.data.success === true) {
          const emptyArray = [];
          setErrors(emptyArray);
          setSuccess(true);
          if (response.data.message) {
            setMessage(response.data.message);
          } else {
            setMessage("Vector Store Created for support!!");
          }

        } else {
          setSuccess(false);
          setErrors([response.data.errors.message]);
        }
      })
      .catch(error => {
        setSuccess(false);
        setErrors([error.response.data.errors.message]);
      });
  }

  const onClickApprove = async (e) => {
    e.preventDefault();
    setResult(answer);
    
  }

  const onClickEdit = async (e) => {
    e.preventDefault();
    setDisplayEditForm(1);
    setEditAnswer(answer);
    
  }

  const onClickEditSubmit = async (e) => {
    e.preventDefault();
    setEditSubmitBtnLoading(true);

    const emptyArray = [];
    setErrors(emptyArray);

    const url = `https://backendai1.klee.com/api/v1/topic/support/add-json`;

    const headers = {
      'Accept': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Credentials': 'true',
      'Access-Control-Allow-Headers': 'accept, Content-type'
    }

    const data = new FormData();
    data.append('question', text);
    data.append('answer', editAnswer);

    const response = await axios.post(url, data, { headers: headers })
      .then(response => {
        if (response.data.success === true) {
          const emptyArray = [];
          setErrors(emptyArray);
          setSuccess(true);
          if (response.data.message) {
            setMessage(response.data.message);
          } else {
            setMessage("Answer added in json for support!!");
          }

          setResult(editAnswer);
          createVectorStore();
        } else {
          setSuccess(false);
          setErrors([response.data.errors.message]);
        }

        setEditSubmitBtnLoading(false);
      })
      .catch(error => {
        setSuccess(false);
        setErrors([error.response.data.errors.message]);
        setEditSubmitBtnLoading(false);
      });
  }

  const onClickCancel = async (e) => {
    e.preventDefault();
    setDisplayEditForm(0);
  }

  const onClickDownload = async (e) => {
    e.preventDefault();
    setDownloadBtnLoading(true);

    const emptyArray = [];
    setErrors(emptyArray);

    const urll = `https://backendai1.klee.com/api/v1/topic/support/download-json`;

    const response = await axios.get(urll, { responseType: 'blob' });
    
    if(response.error){
      setSuccess(false);
      setErrors(['Something went wrong while downloading json']);
      setDownloadBtnLoading(false);
    }else if(response.data){
      setSuccess(true);
      setMessage("Json downloaded for support!!");

      // Use the response data directly as Blob
      const blob = new Blob([response.data], { type: 'application/json' });

      // Create a temporary link and trigger a download
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'zzzstaticJsonFile.json';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);

      setDownloadBtnLoading(false);
    } 
  }

  useEffect(() => {
    
  }, []);

  return (
    <div className='pageSection w-100'>

      <div className={`alert alert-success formValidationWrapper ${success ? 'd-block' : 'd-none'}`}>
        <p className='mb-0'>{message}</p>
      </div>
      <div className={`alert alert-danger text-left formValidationWrapper ${errors.length > 0 ? 'd-block' : 'd-none'}`}>
        {
          errors.map((error, index) => {
            return (<p key={index} className='mb-0'> {error} </p>);
          })
        }
      </div>

      <div className='container-fluid'>
        <div className='row'>
          <div className="col-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div className='formSectionWrapper'>
              <div className="formSection">
                <div className="form-group">
                  <p className='mb-0'>Query from User</p>
                  {
                    !browserSupportsSpeechRecognition ?
                    <p>Browser doesn't support speech recognition.</p>
                    :
                    <div className='d-flex'>
                      <p className=''>
                        {
                        listening ? 
                        <button className='btn btn-sm btn-danger mt-2' onClick={(e) => stopRecording(e)}><i className="bi-mic-mute-fill"></i></button>
                        :
                        <button className='btn btn-sm btn-info mt-2' onClick={(e) => startRecording(e)}><i className="bi bi-mic"></i></button>
                        }
                      </p>
                      <p className='ms-2 mt-2'>{transcript}</p>
                    </div>
                  }

                  <textarea className="form-control" id="exampleFormControlTextarea1" rows="6" value={text} onChange={(e) => { setText(e.target.value) }}></textarea>
                </div>
                <div className="w-100 d-flex justify-content-end">
                  {
                    submitBtnLoading ?
                      <button id="formbuttonanswerfetching" className="btn btn-light" type="submit">
                        <img src='../img/loader.gif' style={{height: '18px', marginRight: '10px'}}></img>
                        <span>Answer fetching...</span>
                      </button>
                      :
                      <button id="formbuttonsubmit" className="btn btn-info" type="submit" onClick={(e) => submitForm(e)}>
                        <span>Submit</span>
                      </button>
                  }
                </div>
              </div>
            </div>

          </div>
          <div className="col-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">

          </div>
          
          <div className="col-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div className='formSectionWrapper'>
              <div className="formSection">
                <div className="form-group">
                  <p>Answer from GPT</p>
                  <textarea className="form-control" id="exampleFormControlTextarea1" rows="8" value={answer} onChange={(e) => { setAnswer(e.target.value) }}></textarea>
                </div>
                <div className="w-100 d-flex justify-content-end">
                  <button id="formbuttonapprove" className="btn btn-info" type="submit" onClick={(e) => onClickApprove(e)} style={{ marginRight: '20px', width: '200px'}}>
                    <span>Approve</span>
                  </button>
                  <button id="formbuttonedit" className="btn btn-info" type="submit" onClick={(e) => onClickEdit(e)} style={{width: '200px'}}>
                    <span>Edit</span>
                  </button>
                  
                </div>


              </div>
            </div>
          </div>
          
          <div className="col-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
            {
              displayEditForm == 0 ? 
              <></>
              :
              <div className='formSectionWrapper'>
                <div className="formSection">
                  <div className="form-group">
                    <p>Edited Answer</p>
                    <textarea className="form-control" id="exampleFormControlTextarea1" rows="8" value={editAnswer} onChange={(e) => { setEditAnswer(e.target.value) }}></textarea>
                  </div>
                  <div className="w-100 d-flex justify-content-start">
                    {
                      editSubmitBtnLoading ?
                        <button id="formbuttonjsonadding" className="btn btn-light" type="submit" style={{marginRight: '20px', width: '200px'}}>
                          <img src='../img/loader.gif' style={{height: '18px', marginRight: '10px'}}></img>
                          <span>Data editing...</span>
                        </button>
                        :
                        <button id="formbuttoneditsubmit" className="btn btn-info" type="submit" onClick={(e) => onClickEditSubmit(e)} style={{ marginRight: '20px', width: '200px'}}>
                          <span>Submit</span>
                        </button>
                    }
                    <button id="formbuttoncancel" className="btn btn-info" type="submit" onClick={(e) => onClickCancel(e)} style={{width: '200px'}}>
                      <span>Cancel</span>
                    </button>
                    
                  </div>
  
  
                </div>
              </div>
            }
          </div>
          
          <div className="col-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div className='formSectionWrapper'>
              <div className="formSection">
                <div className="form-group">
                  <p>Answer to User by Moderator</p>
                  <textarea className="form-control" id="exampleFormControlTextarea1" rows="8" value={result} onChange={(e) => { setResult(e.target.value) }}></textarea>
                </div>
                <div className="w-100 d-flex justify-content-end">
                    {
                      downloadBtnLoading ?
                        <button id="formbuttonjsondownloading" className="btn btn-light" type="submit" style={{width: '200px'}}>
                          <img src='../img/loader.gif' style={{height: '18px', marginRight: '10px'}}></img>
                          <span>Json-downloading...</span>
                        </button>
                        :
                        <button id="formbuttoneditdownload" className="btn btn-info" type="submit" onClick={(e) => onClickDownload(e)} style={{ width: '200px'}}>
                          <span>Download</span>
                        </button>
                    }
                  </div>

              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  );
};

export default Query;
import React, { useState, useEffect } from 'react';
import axios from 'axios';

const ManagerPageCreate = () => {

  const [errors, setErrors] = useState([]);
  const [success, setSuccess] = useState(false);
  const [message, setMessage] = useState('');
  const [submitBtnLoading, setSubmitBtnLoading] = useState(false);

  const [title, setTitle] = useState('');
  const [prompt, setPrompt] = useState('');
  const [lang, setLang] = useState('EN');


  const submitForm = async (e) => {
    e.preventDefault();
    setSubmitBtnLoading(true);

    const emptyArray = [];
    setErrors(emptyArray);

    const url = `https://backendai1.klee.com/api/v1/speechToText/upload-file`;

    const headers = {
      'Accept': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Credentials': 'true',
      'Access-Control-Allow-Headers': 'accept, Content-type'
    }

    const data = new FormData();
    data.append('title', title);
    data.append('prompt', prompt);
    data.append('lang', lang);

    const response = await axios.post(url, data, { headers: headers })
      .then(response => {
        if (response.data.success === true) {
          const emptyArray = [];
          setErrors(emptyArray);
          setSuccess(true);
          if (response.data.message) {
            setMessage(response.data.message);
          } else {
            setMessage("File uploaded for speech to text!!");
          }
          setTitle('');
          setPrompt('');
          setLang('EN');
          
          setSubmitBtnLoading(false);
        } else {
          setSuccess(false);
          setErrors([(response.data.errors.message ? response.data.errors.message : response.data.message)]);
          setSubmitBtnLoading(false);
        }

      })
      .catch(error => {
        setSuccess(false);
        setErrors([error.response.data.message]);
        setSubmitBtnLoading(false);
      });
  }


  useEffect(() => {
    

  }, []);

  return (
    <div className='pageSection w-100'>

      <div className={`alert alert-success formValidationWrapper ${success ? 'd-block' : 'd-none'}`}>
        <p className='mb-0'>{message}</p>
      </div>
      <div className={`alert alert-danger text-left formValidationWrapper ${errors.length > 0 ? 'd-block' : 'd-none'}`}>
        {
          errors.map((error, index) => {
            return (<p key={index} className='mb-0'> {error} </p>);
          })
        }
      </div>

      <div className='container-fluid'>
        <div className='row'>

          <div className="col-12 col-lg-9 col-md-9 col-sm-12 col-xs-12">
            <div className='formSectionWrapper'>
              <div className="formSection">
                <div className="form-group">
                  <h2 className='text-start mb-5'>Manager Page > Upload New</h2>
                </div>
              </div>

              <div className="formSection">
                <table className='ms-lg-4 ms-md-4 w-100'>
                  <tbody>
                    <tr>
                      <td colSpan="2" className='text-start'>Title:</td>
                      <td colSpan="8">
                        <input className="form-control" id="titleInputField" value={title} onChange={(e) => { setTitle(e.target.value) }} />
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="2" className='text-start'>GPT Prompt:</td>
                      <td colSpan="8">
                        <textarea className="form-control" id="promptInputField" rows="5" value={prompt} onChange={(e) => { setPrompt(e.target.value) }}></textarea>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="2" className='text-start'>Language:</td>
                      <td colSpan="8">
                        <select className="form-control" id="langInputField" value={lang} onChange={(e) => { setLang(e.target.value) }}>
                          <option value="EN">English(EN)</option>
                          <option value="FR">French(FR)</option>
                          <option value="KR">Korean(KR)</option>
                          <option value="JP">Japanese(JP)</option>
                          <option value="ES">Spanish(ES)</option>
                          <option value="DE">German(DE)</option>
                      </select>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="2" className="w-100 d-flex justify-content-start">
                        {
                          submitBtnLoading ?
                            <button id="formbutton" className="btn btn-light" type="submit">
                              <img src='../img/loader.gif' style={{ height: '18px', marginRight: '10px' }} alt="loader" />
                              <span>File uploading...</span>
                            </button>
                            :
                            <button id="formbutton" className="btn btn-info" type="submit" onClick={(e) => submitForm(e)}>
                              <span>Submit</span>
                            </button>
                        }
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

            </div>
          </div>


        </div>
        
      </div>
    </div>
  );
};

export default ManagerPageCreate;